import {
  FolderApi,
  ProfileApi,
  UserApi,
  SettingApi,
  FriendApi,
  TrophyApi,
  MypageApi,
} from "~/plugins/openapi/api";
import ConfigurationBuilder from "./api/configuration_builder";
import { mockGetMypageCommand } from "./_mock";

/**
 * ヘッダ表示用のユーザ情報を取得
 */
export class GetUserInfoCommand {
  constructor($userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
    this.$userApi = $userApi;
  }

  /**
   * @return {Promise<{
   *  crystal: {
   *    free: number,
   *    paid: number,
   *  },
   *  monthly_rank: string | null,
   *  user: object,
   *  user_class: string,
   *  user_class_icon_path: string,
   *  target_year: string,
   *  card_master_updated_at: string,
   * }> | Promise<void>}
   */
  execute() {
    try {
      return this.$userApi
        .userUserInfoApiPost()
        .then(({ data }) => data);
    } catch (error) {
      console.error(error);
    }
  }
}

/**
 * folderを取得
 */
export class GetFolderCommand {
  /**
   * @param {number | undefined} targetYear
   */
  constructor(
    targetYear,
    $folderApi = new FolderApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.targetYear = targetYear;
    this.$folderApi = $folderApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$folderApi
      .folderApiPost(this.targetYear)
      .then(({ data }) => data);
  }
}

/**
 * profileを取得
 */
export class GetProfileCommand {
  constructor(
    $profileApi = new ProfileApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$profileApi = $profileApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$profileApi.profileApiPost().then(({ data }) => data);
  }
}

/**
 * profile card(表面)を取得
 */
export class GetProfileFrontCommand {
    constructor(
        $profileApi = new ProfileApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$profileApi = $profileApi;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        return this.$profileApi.profileFrontApiPost().then(({ data }) => data);
    }
}

/**
 * profile card(裏面)を取得
 */
export class GetProfileBackCommand {
    constructor(
        $profileApi = new ProfileApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$profileApi = $profileApi;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        return this.$profileApi.profileBackApiPost().then(({ data }) => data);
    }
}

/**
 * profile card(称号)を取得
 */
export class GetProfileTitleCommand {
    constructor(
        $profileApi = new ProfileApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$profileApi = $profileApi;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        return this.$profileApi.profileTitleApiPost().then(({ data }) => data);
    }
}

/**
 * profile card(称号)を取得
 */
export class GetProfileBackgroundCommand {
    constructor(
        $profileApi = new ProfileApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$profileApi = $profileApi;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        return this.$profileApi.profileBackgroundApiPost().then(({ data }) => data);
    }
}
/**
 * profileを取得
 */
 export class UploadProfileAvatarCommand {
  constructor(
    data,
    $userApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$userApi = $userApi;

    this.content_type = data['headers']['content-type'];
    this.accept = data['headers']['accept'];
    this.photo = data['form']['photo'];
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$userApi.apiUploadprofileimagePost(this.content_type, this.accept, this.photo).then(({ data }) => data);
  }
}

/**
 * profileを更新
 */
 export class UpdateProfileCommand {
  constructor(
    $profileApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$profileApi = $profileApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute(data) {
    const { csrf_token, profile, nickname, sex, sex_access, hometown, hometown_access, favorite_player} = data;
    return this.$profileApi.userSaveProfilePost(csrf_token, profile, nickname, sex, sex_access, hometown, hometown_access, favorite_player).then(({ data }) => data);
  }
}

/**
 * csrfを取得
 */
 export class GetCsrfTokenCommand {
  constructor(
    $userApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$userApi = $userApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute(data) {
    return this.$userApi.userGetCsrfTokenApiPost().then(({ data }) => data);
  }
}

/*
 * ブロッキング解除
 */
 export class UnblockUserCommand {
  constructor(
    $userApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$userApi = $userApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute(data) {
    const { user_id, block_id } = data;
    return this.$userApi.userAjaxUnblockPost(user_id, block_id).then(({ data }) => data);
  }
}


/**
 * crystal_purchaseを取得
 */
export class GetCrystalPurchaseCommand {
  constructor(
    $userApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$userApi = $userApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$userApi.userCrystalPurchaseApiPost().then(({ data }) => data);
  }
}

/**
 * settingを取得
 */
export class GetSettingCommand {
  constructor(
    $settingApi = new SettingApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$settingApi = $settingApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$settingApi.settingApiPost().then(({ data }) => data);
  }
}

/**
 * friendを取得
 */
export class GetFriendCommand {
  constructor(
    view,
    keyword,
    $friendApi = new FriendApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$friendApi = $friendApi;
    this.view = view;
    this.keyword = keyword;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$friendApi.friendApiPost(this.view, this.keyword).then(({ data }) => data);
  }
}

/**
 * trophyを取得
 */
export class GetTrophyCommand {
  constructor(
    $trophyApi = new TrophyApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$trophyApi = $trophyApi;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    return this.$trophyApi.trophyApiPost().then(({ data }) => data);
  }
}

/**
 * salselistを取得
 */
//  export class GetSalselistCommand {

//   /**
//    * @throws {AxiosError}
//    */
//   execute() {
//     return globalAxios.post(`${process.env.FRONT_API_URL}/saleslist_api`, {
//     }).then((res) => res.data);
//   }
// }

/**
 * mypageを取得
 */
export class GetMypageCommand {
  /**
   * @param {string} uid
   */
  constructor(
    uid,
    $mypageApi = new MypageApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.$mypageApi = $mypageApi;
    this.uid = uid;
  }

  /**
   * @throws {AxiosError}
   */
  execute() {
    // return mockGetMypageCommand();
    return this.$mypageApi
      .userMypageApiUidPost(this.uid)
      .then(({ data }) => data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
}

/**
 * 新規ユーザ登録
 */
 export class RegisterUserCommand {
  constructor(
    userApi = new UserApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.userApi = userApi;
  }

  /**
   * @return {Promise<any>}
   * @throws {AxiosError}
   */
  execute() {
    return this.userApi
      .userRegisterApiPost()
      .then(({ data }) => data);
  }
}

export class GetUserLinkAppApiGetCommand {
    constructor($userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
        this.$userApi = $userApi;
    }

    execute() {
        try {
            return this.$userApi
                .userLinkAppApiPost()
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}
export class GetUserAjaxLinkAppConfirmPostCommand {
    constructor(email, password, $userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
        this.$userApi = $userApi;
        this.email = email;
        this.password = password;
    }

    execute() {
        try {
            return this.$userApi
                .userAjaxLinkAppConfirmPost(this.email, this.password)
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}

export class GetUserAjaxLinkAppExecutePostCommand {
    constructor(pin_code, $userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
        this.$userApi = $userApi;
        this.pin_code = pin_code;
    }

    execute() {
        try {
            return this.$userApi
                .userAjaxLinkAppExecutePost(this.pin_code)
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}
export class GetUserLinkNftApiGetCommand {
    constructor($userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
        this.$userApi = $userApi;
    }

    execute() {
        try {
            return this.$userApi
                .userLinkNftApiPost()
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}
export class GetUserAjaxLinkNftExecutePostCommand {
    constructor($userApi = new UserApi(new ConfigurationBuilder().buildDefault())) {
        this.$userApi = $userApi;
    }

    execute() {
        try {
            return this.$userApi
                .userAjaxLinkNftExecutePost()
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}
/**
 * profileを更新
 */
export class UpdateProfileFrontCommand {
    constructor(
        nickname,
        $api = new UserApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$api = $api;
        this.nickname = nickname;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        try {
            return this.$api.userAjaxSaveProfileFrontPost(this.nickname)
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }

}
/**
 * profileを更新
 */
export class UpdateProfileBackgroundCommand {
    constructor(
        profile_background_id,
        $api = new UserApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$api = $api;
        this.profile_background_id = profile_background_id;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        try {
            return this.$api.userAjaxSaveProfileBackgroundPost(this.profile_background_id)
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}
/**
 * profileを更新
 */
export class UpdateProfileTitleCommand {
    constructor(
        profile_title_id,
        $api = new UserApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$api = $api;
        this.profile_title_id = profile_title_id;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        try {
            return this.$api.userAjaxSaveProfileTitlePost(this.profile_title_id)
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}

export class PostLogoutCommand {
    constructor(
        $api = new UserApi(new ConfigurationBuilder().buildDefault())
    ) {
        this.$api = $api;
    }

    /**
     * @throws {AxiosError}
     */
    execute() {
        try {
            return this.$api.userLogoutApiPost('')
                .then(({ data }) => data);
        } catch (error) {
            console.error(error);
        }
    }
}